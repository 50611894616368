import React, { useEffect, useState } from 'react';
import { getAllProductApi, getAllCategoryApi, getAllSubcategoriesApi } from '../../../Apis/Api';

const TimeFrame = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState({});
  const [subCategories, setSubCategories] = useState({});
  const [categorizedProducts, setCategorizedProducts] = useState({ red: [], yellow: [], safe: [] });
  const [loading, setLoading] = useState(true); // State to track loading status

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productResponse, categoryResponse, subCategoryResponse] = await Promise.all([
          getAllProductApi(),
          getAllCategoryApi(),
          getAllSubcategoriesApi()
        ]);

        const categoryMap = categoryResponse.data.categories.reduce((map, category) => {
          map[category._id] = category.name;
          return map;
        }, {});

        const subCategoryMap = subCategoryResponse.data.subCategories.reduce((map, subCategory) => {
          map[subCategory._id] = subCategory.name;
          return map;
        }, {});

        setCategories(categoryMap);
        setSubCategories(subCategoryMap);
        setProducts(productResponse.data.products);
        categorizeProducts(productResponse.data.products);
        setLoading(false); // Set loading to false after data is loaded
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false even in case of error
      }
    };

    fetchData();
  }, []);

  const categorizeProducts = (products) => {
    const now = new Date();
    const red = [], yellow = [], safe = [];
  
    products.forEach((product) => {
      const addedDate = new Date(product.createdAt);
      const diffInDays = Math.floor((now - addedDate) / (1000 * 60 * 60 * 24));
      const diffInMonths = diffInDays / 30;
  
      if (diffInMonths >= 6) {  
        red.push(product);
      } else if (diffInMonths >= 3) { 
        yellow.push(product);
      } else { 
        safe.push(product);
      }
    });
  
    setCategorizedProducts({ red, yellow, safe });
  };
  

  const calculateRemainingTime = (createdAt) => {
    const now = new Date();
    const addedDate = new Date(createdAt);
    const diffInDays = Math.floor((now - addedDate) / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  const sortProductsByRemainingTime = (products) => {
    return products.sort((a, b) => calculateRemainingTime(a.createdAt) - calculateRemainingTime(b.createdAt));
  };

  const SkeletonLoader = () => (
    <div className="p-2 bg-gray-300 animate-pulse rounded-md mb-2">
      <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
      <div className="h-3 bg-gray-400 rounded w-1/2 mb-2"></div>
      <div className="h-3 bg-gray-400 rounded w-1/3 mb-2"></div>
      <div className="h-3 bg-gray-400 rounded w-3/4"></div>
    </div>
  );

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Product Inventory Status</h2>

      <div className="flex space-x-4 overflow-x-auto">
        {['red', 'yellow', 'safe'].map((zone) => (
          <div
            key={zone}
            className={`flex-1 min-w-[300px] relative`}
          >
            {/* Zone Title */}
            <div className={`absolute top-0 left-0 w-full text-center py-2 text-white font-semibold 
              ${zone === 'red' ? 'bg-red-600' : zone === 'yellow' ? 'bg-yellow-500' : 'bg-green-600'}`}
            >
              {zone.toUpperCase()} Zone ({categorizedProducts[zone].length} items)
            </div>

            {/* Zone Content with background */}
            <div className={`bg-opacity-50 p-4 rounded-lg min-h-[200px] shadow mt-12
              ${zone === 'red' ? 'bg-red-100' : zone === 'yellow' ? 'bg-yellow-100' : 'bg-green-100'}`}
            >
              {loading
                ? // Show skeleton loaders while loading
                  Array(5).fill(0).map((_, index) => <SkeletonLoader key={index} />)
                : // Show product data when loading is done
                  sortProductsByRemainingTime(categorizedProducts[zone]).map((product) => {
                    const remainingTime = calculateRemainingTime(product.createdAt);
                    const transitionMessage = zone === 'safe' 
                      ? `Time remaining to go to Yellow Zone: ${remainingTime} days`
                      : zone === 'yellow'
                        ? `Time remaining to go to Red Zone: ${remainingTime} days`
                        : `Time already in Red Zone: ${remainingTime} days`;

                    return (
                      <div key={product._id} className="p-2 bg-white rounded-md shadow mb-2">
                        <h4 className="text-md font-semibold">{product.name}</h4>
                        <p className="text-sm">Total Quantity Left: {product.totalQuantity}</p>
                        <p className="text-sm">Added: {new Date(product.createdAt).toLocaleDateString()}</p>
                        <p className="text-sm">Category: {categories[product.category] || 'Unknown'}</p>
                        <p className="text-sm">Subcategory: {subCategories[product.subcategory] || 'Unknown'}</p>
                        <p className="text-sm">Sizes Available:</p>
                        <ul className="ml-4 text-sm">
                          {product.sizes.map((size) => (
                            <li key={size._id}>{size.size} - Total: {size.totalSizeQuantity}</li>
                          ))}
                        </ul>

                        <p className={`text-sm ${zone === 'red' ? 'text-red-600' : zone === 'yellow' ? 'text-yellow-500' : 'text-green-600'}`}>
                          {transitionMessage}
                        </p>
                      </div>
                    );
                  })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeFrame;
