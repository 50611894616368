import React, { useState, useEffect } from 'react';
import { getAllCheckoutsApi, getDailySales, getProductAnalytics } from '../../../Apis/Api';
import * as XLSX from 'xlsx';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { toast } from 'react-hot-toast';
import {
  FiDollarSign,
  FiShoppingBag,
  FiPieChart,
  FiUsers,
  FiFileText,
  FiCreditCard,
  FiBox,
  FiTrendingUp,
  FiDownload,
  FiX
} from 'react-icons/fi';
Chart.register(...registerables);

const NewGetCheckoutAdmin = () => {
  const [checkouts, setCheckouts] = useState([]);
  const [dailySales, setDailySales] = useState([]);
  const [productAnalytics, setProductAnalytics] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [viewMode, setViewMode] = useState('transactions');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [
          { data: checkoutsData },
          { data: salesData },
          { data: analyticsData }
        ] = await Promise.all([
          getAllCheckoutsApi(),
          getDailySales(),
          getProductAnalytics()
        ]);
        setCheckouts(checkoutsData);
        setDailySales(salesData);
        setProductAnalytics(analyticsData);
      } catch (error) {
        toast.error('Failed to load sales data');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const filteredCheckouts = checkouts.filter(checkout => {
    const checkoutDate = new Date(checkout.createdAt);
    const start = startDate ? new Date(startDate) : new Date(-8640000000000000);
    const end = endDate ? new Date(endDate) : new Date(8640000000000000);
    end.setDate(end.getDate() + 1);
    return checkoutDate >= start && checkoutDate <= end;
  });

  const calculateProfitMetrics = () => {
    return filteredCheckouts.reduce((acc, checkout) => {
      const totalCost = checkout.cart.reduce(
        (sum, item) => sum + (item.productId.price * item.quantity),
        0
      );
      const transactionProfit = checkout.totalAmount - totalCost;
      acc.totalProfit += transactionProfit;
      acc.totalRevenue += checkout.totalAmount;
      acc.totalCost += totalCost;
      return acc;
    }, { totalProfit: 0, totalRevenue: 0, totalCost: 0 });
  };

  const exportExcel = () => {
    const { totalProfit, totalRevenue, totalCost } = calculateProfitMetrics();
    const checkoutData = filteredCheckouts.map((checkout, index) => {
      const discountedAmount = checkout.cart.reduce(
        (sum, product) => sum + (product.price * product.quantity),
        0
      ) - checkout.totalAmount;

      const profitMade = checkout.totalAmount - checkout.cart.reduce(
        (sum, product) => sum + (product.productId.price * product.quantity),
        0
      );

      const paymentMethods = {
        "Cash": checkout.paymentMethod === "cash" ? checkout.totalAmount : 0,
        "Esewa": checkout.paymentMethod === "esewa" ? checkout.totalAmount : 0,
        "FonePay": checkout.paymentMethod === "fonepay" ? checkout.totalAmount : 0,
        "Khalti": checkout.paymentMethod === "khalti" ? checkout.totalAmount : 0,
      };

      return {
        "S.N": index + 1,
        "Date & Time": `${new Date(checkout.createdAt).toLocaleDateString()} ${new Date(checkout.createdAt).toLocaleTimeString()}`,
        "Product Details": checkout.cart.map((product) => [
          `Name: ${product.name}`,
          `Size: ${product.size}`,
          `Color: ${product.color}`,
          `Quantity: ${product.quantity}`,
          `Actual Price: NPR ${product.productId.price}`,
          `Sold at Cost: NPR ${product.price}`,
          `Profit: NPR ${(product.price * product.quantity) - (product.productId.price * product.quantity)}`
        ].join("\n")).join("\n\n"),
        "Payment Method": checkout.paymentMethod.charAt(0).toUpperCase() + checkout.paymentMethod.slice(1),
        "Quantity": checkout.cart.reduce((total, product) => total + product.quantity, 0),
        "Customer Name": checkout.customer?.name || "N/A",
        "Customer Mobile": checkout.customer?.phone || "N/A",
        "Remarks": checkout.remarks || "N/A",
        "Cash": paymentMethods["Cash"],
        "Esewa": paymentMethods["Esewa"],
        "FonePay": paymentMethods["FonePay"],
        "Khalti": paymentMethods["Khalti"],
        "Total Amount": checkout.totalAmount,
        "Discounted Amount": discountedAmount,
        "Total Cost": checkout.cart.reduce((sum, item) => sum + (item.productId.price * item.quantity), 0),
        "Total Profit": profitMade,
        "Profit Margin": `${((profitMade / checkout.totalAmount) * 100 || 0).toFixed(2)}%`
      };
    });

    const ws = XLSX.utils.json_to_sheet(checkoutData);
    const totalRows = checkoutData.length + 1;
    const summaryRows = [
      {},
      {
        "S.N": "SUMMARY",
        "Total Amount": totalRevenue,
        "Total Cost": totalCost,
        "Total Profit": totalProfit,
        "Profit Margin": `${((totalProfit / totalRevenue) * 100).toFixed(2)}%`
      },
      {
        "S.N": "PAYMENT TOTALS",
        "Cash": { t: "n", f: `SUM(I2:I${totalRows})` },
        "Esewa": { t: "n", f: `SUM(J2:J${totalRows})` },
        "FonePay": { t: "n", f: `SUM(K2:K${totalRows})` },
        "Khalti": { t: "n", f: `SUM(L2:L${totalRows})` },
        "Total Amount": { t: "n", f: `SUM(M2:M${totalRows})` }
      }
    ];

    XLSX.utils.sheet_add_json(ws, summaryRows, { skipHeader: true, origin: -1 });
    ws['!cols'] = [
      { wch: 5 }, { wch: 20 }, { wch: 50 }, { wch: 15 },
      { wch: 10 }, { wch: 20 }, { wch: 15 }, { wch: 15 },
      { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 },
      { wch: 15 }, { wch: 15 }, { wch: 15 }
    ];

    const currencyColumns = [8, 9, 10, 11, 12, 13, 14];
    for(let R = 1; R <= totalRows + 2; R++){
      currencyColumns.forEach(C => {
        const cell = XLSX.utils.encode_cell({c:C, r:R});
        if(ws[cell]) ws[cell].z = 'NPR#,##0.00';
      });
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sales Report');
    XLSX.writeFile(wb, `sales-report-${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  const CustomerModal = ({ customer, onClose }) => {
    const customerTransactions = checkouts.filter(c => c.customer?.phone === customer.phone);
    const totalSpent = customerTransactions.reduce((sum, c) => sum + c.totalAmount, 0);
    const totalProfit = customerTransactions.reduce(
      (sum, c) => sum + (c.totalAmount - c.cart.reduce((a, b) => a + (b.productId.price * b.quantity), 0)),
      0
    );

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-auto">
          <div className="p-6 border-b flex justify-between items-center">
            <h2 className="text-xl font-bold">{customer.name || 'Guest Customer'}</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <FiX size={24} />
            </button>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="p-4 bg-blue-50 rounded-lg">
                <div className="text-sm text-gray-600">Total Transactions</div>
                <div className="text-2xl font-bold text-blue-600">
                  {customerTransactions.length}
                </div>
              </div>
              <div className="p-4 bg-green-50 rounded-lg">
                <div className="text-sm text-gray-600">Total Profit</div>
                <div className="text-2xl font-bold text-green-600">
                  NPR {totalProfit.toFixed(2)}
                </div>
              </div>
            </div>
            <div className="mb-6 grid grid-cols-2 gap-4">
              <div className="p-4 bg-purple-50 rounded-lg">
                <div className="text-sm text-gray-600">Average Transaction Value</div>
                <div className="text-xl font-bold text-purple-600">
                  NPR {(totalSpent / customerTransactions.length || 0).toFixed(2)}
                </div>
              </div>
              <div className="p-4 bg-orange-50 rounded-lg">
                <div className="text-sm text-gray-600">Profit Margin</div>
                <div className="text-xl font-bold text-orange-600">
                  {((totalProfit / totalSpent) * 100 || 0).toFixed(2)}%
                </div>
              </div>
            </div>
            <h3 className="text-lg font-semibold mb-4">Transaction History</h3>
            {customerTransactions.map((checkout, index) => {
              const profit = checkout.totalAmount - 
                checkout.cart.reduce((sum, item) => sum + (item.productId.price * item.quantity), 0);
              
              return (
                <div key={index} className="p-4 border-b hover:bg-gray-50">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <div className="font-medium">
                        {new Date(checkout.createdAt).toLocaleDateString()}
                      </div>
                      <div className="text-sm text-gray-500">
                        {checkout.cart.length} items · {checkout.paymentMethod.toUpperCase()}
                      </div>
                      {checkout.remarks && (
                        <div className="text-sm text-gray-500 mt-1">
                          Remarks: {checkout.remarks}
                        </div>
                      )}
                    </div>
                    <div className="text-right">
                      <div className="text-lg font-bold">NPR {checkout.totalAmount.toFixed(2)}</div>
                      <div className="text-sm text-green-600">
                        Profit: NPR {profit.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            )}
          </div>
        </div>
      </div>
    );
  };

  const StatCard = ({ icon, title, value, secondary, color = 'blue' }) => (
    <div className={`bg-white p-4 rounded-lg shadow flex items-center gap-4 border-l-4 border-${color}-500`}>
      <div className={`p-3 bg-${color}-100 rounded-full text-${color}-600`}>
        {icon}
      </div>
      <div>
        <div className="text-sm text-gray-500">{title}</div>
        <div className="text-xl font-bold">{value}</div>
        {secondary && <div className="text-xs text-gray-400 mt-1">{secondary}</div>}
      </div>
    </div>
  );

  if (loading) return <div className="text-center p-8">Loading sales data...</div>;

  const { totalProfit, totalRevenue, totalCost } = calculateProfitMetrics();
  const profitMargin = ((totalProfit / totalRevenue) * 100) || 0;

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <div className="flex flex-col md:flex-row justify-between mb-6 gap-4">
        <div>
          <h1 className="text-2xl font-bold flex items-center gap-2">
            <FiPieChart className="text-blue-600" /> Sales Dashboard
          </h1>
          <p className="text-sm text-gray-500 mt-1">
            {new Date().toLocaleDateString('en-US', { 
              weekday: 'long', 
              year: 'numeric', 
              month: 'long', 
              day: 'numeric' 
            })}
          </p>
        </div>
        <div className="flex flex-wrap gap-4 items-center">
          <div className="flex gap-2 items-center bg-white p-2 rounded-lg border">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="p-1 rounded text-sm"
            />
            <span className="text-gray-400">to</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="p-1 rounded text-sm"
            />
          </div>
          <button
            onClick={exportExcel}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 flex items-center gap-2"
          >
            <FiDownload /> Export Report
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <StatCard
          icon={<FiDollarSign size={24} />}
          title="Total Sales"
          value={`NPR ${totalRevenue.toFixed(2)}`}
          secondary={`${filteredCheckouts.length} transactions`}
          color="blue"
        />
        <StatCard
          icon={<FiShoppingBag size={24} />}
          title="Products Sold"
          value={filteredCheckouts.reduce((sum, c) => 
            sum + c.cart.reduce((a, b) => a + b.quantity, 0), 0)}
          secondary={`${new Set(filteredCheckouts.map(c => c.customer?.phone)).size} customers`}
          color="green"
        />
        <StatCard
          icon={<FiTrendingUp size={24} />}
          title="Total Profit"
          value={`NPR ${totalProfit.toFixed(2)}`}
          secondary={`Margin: ${profitMargin.toFixed(2)}%`}
          color="purple"
        />
        <StatCard
          icon={<FiUsers size={24} />}
          title="Payment Methods"
          value={Object.entries(
            filteredCheckouts.reduce((acc, c) => {
              acc[c.paymentMethod] = (acc[c.paymentMethod] || 0) + 1;
              return acc;
            }, {})
          ).map(([method, count]) => `${method}: ${count}`).join(', ')}
          color="orange"
        />
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-lg font-semibold flex items-center gap-2">
            <FiFileText className="text-gray-600" /> Transaction Details
          </h2>
          <div className="flex gap-2">
            <button
              onClick={() => setViewMode('transactions')}
              className={`px-4 py-2 rounded ${viewMode === 'transactions' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
            >
              Transactions
            </button>
            <button
              onClick={() => setViewMode('products')}
              className={`px-4 py-2 rounded ${viewMode === 'products' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
            >
              Products
            </button>
          </div>
        </div>

        {viewMode === 'transactions' ? (
          <div className="divide-y">
            {filteredCheckouts.map(checkout => (
              <div 
                key={checkout._id} 
                className="p-4 hover:bg-gray-50 transition-colors cursor-pointer"
                onClick={() => checkout.customer?.phone && setSelectedCustomer(checkout.customer)}
              >
                <div className="flex flex-col md:flex-row justify-between gap-4">
                  <div className="flex-1">
                    <div className="flex items-center gap-2 mb-1">
                      <span className="font-medium">
                        {new Date(checkout.createdAt).toLocaleDateString()}
                      </span>
                      <span className="text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800 capitalize">
                        <FiCreditCard className="inline mr-1" /> {checkout.paymentMethod}
                      </span>
                    </div>
                    <div className="text-sm text-gray-600">
                      {checkout.customer?.name || 'Guest Customer'} · {checkout.customer?.phone || 'N/A'}
                    </div>
                    {checkout.remarks && (
                      <div className="text-sm text-gray-500 mt-1">
                        Remarks: {checkout.remarks}
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    <div className="text-lg font-bold text-blue-600">
                      NPR {checkout.totalAmount.toFixed(2)}
                    </div>
                    <div className="text-sm text-green-600">
                      + NPR {(  checkout.totalAmount - checkout.cart.reduce((sum, item) => sum + (item.productId.price * item.quantity), 0).toFixed(2))}
                    </div>
                  </div>
                </div>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-2">
                  {checkout.cart.map((item, i) => (
                    <div key={i} className="p-3 bg-gray-50 rounded-lg">
                      <div className="flex justify-between items-start">
                        <div>
                          <div className="font-medium">
                            {item.quantity}x {item.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {item.size}/{item.color}
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="font-medium">
                            NPR {(item.price * item.quantity).toFixed(2)}
                          </div>
                          <div className="text-xs text-gray-500">
                            {(
                              ((item.price - item.productId.price) / 
                              item.price * 100
                            ).toFixed(1))}% margin
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="p-3 text-left">Product</th>
                  <th className="p-3 text-left">Sold</th>
                  <th className="p-3 text-left">Revenue</th>
                  <th className="p-3 text-left">Cost</th>
                  <th className="p-3 text-left">Profit</th>
                  <th className="p-3 text-left">Margin</th>
                </tr>
              </thead>
              <tbody>
                {productAnalytics.map(product => (
                  <tr key={product.name} className="border-t hover:bg-gray-50">
                    <td className="p-3">{product.name}</td>
                    <td className="p-3">{product.totalSold}</td>
                    <td className="p-3">NPR {product.totalRevenue.toFixed(2)}</td>
                    <td className="p-3">NPR {product.totalCost.toFixed(2)}</td>
                    <td className="p-3 text-green-600">NPR {product.totalProfit.toFixed(2)}</td>
                    <td className="p-3">
                      {((product.totalProfit / product.totalRevenue) * 100 || 0).toFixed(2)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {selectedCustomer && (
        <CustomerModal 
          customer={selectedCustomer} 
          onClose={() => setSelectedCustomer(null)} 
        />
      )}
    </div>
  );
};

export default NewGetCheckoutAdmin;