import React, { useState, useEffect } from 'react';
import WeeklySale from '../component/WeeklySale';
import OrderList from './orderHistory/OrderList';
import { getCategorySalesApi, getTotalCountsApi } from '../../Apis/Api';
import axios from 'axios';
import WeeklySaleChart from './WeeklySaleChart';

const Dashboard = () => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);
  const [totalSubcategories, setTotalSubcategories] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    getTotalCountsApi()
      .then((response) => {
        console.log(response);
        setTotalProducts(response.data.data.totalProducts);
        setTotalCategories(response.data.data.totalCategories);
        setTotalSubcategories(response.data.data.totalSubCategories);
      })
      .catch((error) => console.error("Error fetching total counts:", error));
  }, []);

  return (
    <div className="p-6  min-h-screen">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Dashboard</h1>
      <div className="flex justify-between items-center gap-6">
        <div className="flex-1 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
          <h2 className="text-lg font-medium text-gray-600">Total Products</h2>
          <h1 className="text-4xl font-extrabold text-gray-800 mt-2">{totalProducts}</h1>
        </div>

        <div className="flex-1 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
          <h2 className="text-lg font-medium text-gray-600">Total SubCategories</h2>
          <h1 className="text-4xl font-extrabold text-gray-800 mt-2">{totalSubcategories}</h1>
        </div>

        <div className="flex-1 bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
          <h2 className="text-lg font-medium text-gray-600">Total Categories</h2>
          <h1 className="text-4xl font-extrabold text-gray-800 mt-2">{totalCategories}</h1>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-8">
  <div>
    <WeeklySale />
  </div>
  <div>
    <WeeklySaleChart />
  </div>
</div>


      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4 text-gray-800">Delivery Status</h2>
        <OrderList />
      </div>
    </div>
  );
};

export default Dashboard;
