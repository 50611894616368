import React, { useState, useEffect } from 'react';
import { baseURL, getProductBySku, processCheckouts, checkCustomer, getCustomerHistory } from '../../../Apis/Api';
import toast from 'react-hot-toast';

const NewSelfCheckout = () => {
  // State management
  const [barcode, setBarcode] = useState('');
  const [cart, setCart] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [customerStatus, setCustomerStatus] = useState('new');
  const [customerHistory, setCustomerHistory] = useState(null);
  const [customDiscount, setCustomDiscount] = useState('');
  const [discount, setDiscount] = useState(0);
  // Derived values
  const [discountType, setDiscountType] = useState('');

  const subtotal = cart.reduce((sum, item) => sum + (item.sellingPrice * item.quantity), 0);
  const grandTotal = Math.max(0, subtotal - discount);

  // Discount calculation effect
  useEffect(() => {
    let calculatedDiscount = 0;
    if (discountType === '5') {
      calculatedDiscount = subtotal * 0.05;
    } else if (discountType === '10') {
      calculatedDiscount = subtotal * 0.10;
    } else if (discountType === 'other') {
      calculatedDiscount = Math.min(Number(customDiscount) || 0, subtotal);
    }
    setDiscount(Math.max(0, calculatedDiscount));
  }, [discountType, customDiscount, subtotal]);
  // Customer verification effect
  useEffect(() => {
    const verifyCustomer = async () => {
      if (mobileNumber.length === 10) {
        try {
          const { data } = await checkCustomer(mobileNumber);
          if (data.exists) {
            setCustomerStatus(data.isReturning ? 'returning' : 'existing');
            if (data.name) setCustomerName(data.name);

            const historyResponse = await getCustomerHistory(mobileNumber);
            setCustomerHistory(historyResponse.data.summary);
          } else {
            setCustomerStatus('new');
            setCustomerHistory(null);
          }
        } catch (error) {
          toast.error('Error verifying customer');
        }
      } else {
        setCustomerHistory(null);
      }
    };
    verifyCustomer();
  }, [mobileNumber]);

  // Barcode scanning effect
  useEffect(() => {
    const handleScan = async () => {
      if (!barcode.trim()) return;

      setLoading(true);
      try {
        const { data } = await getProductBySku(barcode);
        if (data?.product) {
          addToCart(data.product);
          toast.success('Product added');
          setBarcode('');
        }
      } catch (error) {
        toast.error('Product not found');
      } finally {
        setLoading(false);
      }
    };

    const timer = setTimeout(handleScan, 500);
    return () => clearTimeout(timer);
  }, [barcode]);

  // Cart operations
  const addToCart = (product) => {
    setCart([...cart, {
      product,
      size: '',
      color: '',
      quantity: 1,
      costPrice: product.price,
      sellingPrice: product.discountedPrice
    }]);
  };

  const updateCartItem = (index, field, value) => {
    setCart(prev => prev.map((item, i) => {
      if (i === index) {
        const updated = { ...item };
        if (field === 'size') {
          updated.color = '';
          updated[field] = value;
        } else if (field === 'quantity') {
          updated[field] = Math.max(1, Number(value));
        } else {
          updated[field] = value;
        }
        return updated;
      }
      return item;
    }));
  };

  const removeCartItem = (index) => {
    setCart(prev => prev.filter((_, i) => i !== index));
  };

  // Checkout handler
  const handleCheckout = async () => {
    if (!cart.every(item => item.size && item.color)) {
      toast.error('Complete size/color for all items');
      return;
    }

    const checkoutData = {
      cart: cart.map(item => ({
        productId: item.product._id,
        name: item.product.name,
        size: item.size,
        color: item.color,
        quantity: item.quantity,
        price: item.sellingPrice,
        costPrice: item.costPrice
      })),
      paymentMethod,
      discount,
      customer: { name: customerName, phone: mobileNumber },
      remarks,
      totalAmount: grandTotal,
    };

    try {
      setLoading(true);
      const { data } = await processCheckouts(checkoutData);
      if (data.success) {
        toast.success('Checkout successful');
        // Reset all states
        setCart([]);
        setPaymentMethod('');
        setCustomerName('');
        setMobileNumber('');
        setRemarks('');
        setDiscount(0);
        setCustomerHistory(null);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || 'Checkout failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="bg-white  p-6 space-y-8">
        {/* Header */}
        <div className="border-b pb-6">
          <h1 className="text-2xl font-bold text-gray-800">Self Checkout System</h1>
          <p className="text-gray-600 mt-1">Scan products and process payments</p>
        </div>

        {/* Scanner & Customer Info */}
        <div className="grid md:grid-cols-2 gap-8">
          {/* Scanner Section */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Barcode Scanner
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  placeholder="Scan product barcode..."
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 pr-12"
                  disabled={loading}
                />
                <div className="absolute right-3 top-3 text-gray-400">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h4M4 8h16M4 16h16" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Customer Details */}
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <h2 className="text-lg font-semibold mb-4">Customer Information</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value.replace(/\D/g, '').slice(0, 10))}
                    placeholder="98XXXXXXXX"
                    className="w-full p-2.5 border rounded-md focus:ring-2 focus:ring-blue-500"
                  />
                  {customerStatus !== 'new' && (
                    <div className="mt-2 flex items-center gap-2">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${customerStatus === 'returning'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-blue-100 text-blue-800'
                        }`}>
                        {customerStatus.charAt(0).toUpperCase() + customerStatus.slice(1)} Customer
                      </span>
                      {customerHistory && (
                        <span className="text-sm text-gray-600">
                          {customerHistory.totalTransactions} previous purchases
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    placeholder="Enter customer name"
                    className="w-full p-2.5 border rounded-md focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Cart Items */}
          <div className="space-y-6">
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <h2 className="text-lg font-semibold mb-4">Cart Items ({cart.length})</h2>
              <div className="space-y-4">
                {cart.map((item, index) => (
                  <div key={index} className="bg-white p-4 rounded-lg border border-gray-200">
                    <div className="flex gap-4">
                      <img
                        src={`${baseURL}/${item.product.images[0]}`}
                        alt={item.product.name}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                      <div className="flex-1">
                        <div className="flex justify-between items-start">
                          <h3 className="font-medium text-gray-800">{item.product.name}</h3>
                          <button
                            onClick={() => removeCartItem(index)}
                            className="text-gray-400 hover:text-red-600 transition-colors"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>

                        <div className="grid grid-cols-2 gap-2 mt-2">
                          <select
                            value={item.size}
                            onChange={(e) => updateCartItem(index, 'size', e.target.value)}
                            className="text-sm p-2 border rounded-md"
                          >
                            <option value="">Select Size</option>
                            {item.product.sizes.map(size => (
                              <option key={size._id} value={size.size}>{size.size}</option>
                            ))}
                          </select>

                          <select
                            value={item.color}
                            onChange={(e) => updateCartItem(index, 'color', e.target.value)}
                            className="text-sm p-2 border rounded-md"
                            disabled={!item.size}
                          >
                            <option value="">Select Color</option>
                            {item.product.sizes
                              .find(s => s.size === item.size)
                              ?.colors.map(color => (
                                <option key={color._id} value={color.color}>
                                  {color.color} ({color.quantity})
                                </option>
                              ))}
                          </select>

                          <div className="col-span-2 flex items-center gap-3">
                            <h1 className="text-sm font-medium">Quantity</h1>
                            <input
                              type="number"
                              value={item.quantity}
                              onChange={(e) => updateCartItem(index, 'quantity', e.target.value)}
                              className="w-16 text-sm p-2 border rounded-md focus:ring focus:ring-blue-200"
                              min="1"
                            />
                            <span className="text-gray-500 text-sm">
                              NPR {item.sellingPrice} × {item.quantity}
                            </span>
                            <span className="ml-auto font-semibold text-base">
                              NPR {(item.sellingPrice * item.quantity).toFixed(2)}
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Payment & Summary */}
            <div className="space-y-6">
              <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                <h2 className="text-lg font-semibold mb-4">Payment Details</h2>
                <div className="space-y-4">
                  <div className="grid md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Payment Method
                      </label>
                      <select
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="w-full p-2.5 border rounded-md focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="">Select Payment Method</option>
                        <option value="cash">Cash</option>
                        <option value="esewa">eSewa</option>
                        <option value="fonepay">Fonepay</option>
                        <option value="khalti">Khalti</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Discount
                      </label>
                      <div className="flex gap-2">
                        <select
                          value={discountType}
                          onChange={(e) => setDiscountType(e.target.value)}
                          className="p-2 border rounded-md flex-1"
                        >
                          <option value="">Select Discount</option>
                          <option value="5">5%</option>
                          <option value="10">10%</option>
                          <option value="other">Custom</option>
                        </select>
                        {discountType === 'other' && (
                          <input
                            type="number"
                            value={customDiscount}
                            onChange={(e) => setCustomDiscount(e.target.value)}
                            className="p-2 border rounded-md w-24"
                            placeholder="Amount"
                            min="0"
                            max={subtotal}
                          />
                        )}
                      </div>
                    </div>

                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Order Notes
                    </label>
                    <textarea
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      placeholder="Add any special instructions..."
                      className="w-full p-2.5 border rounded-md focus:ring-2 focus:ring-blue-500"
                      rows="2"
                    />
                  </div>
                </div>
              </div>

              {/* Order Summary */}
              <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
                <div className="space-y-3">
                  <div className="flex justify-between font-medium">
                    <span>Subtotal:</span>
                    <span>NPR {subtotal.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Discount:</span>
                    <span>- NPR {discount.toFixed(2)}</span>
                  </div>
                  <div className="border-t pt-3 flex justify-between font-bold text-blue-800">
                    <span>Total Amount:</span>
                    <span>NPR {grandTotal.toFixed(2)}</span>
                  </div>
                </div>
              </div>

              {/* Checkout Button */}
              <button
                onClick={handleCheckout}
                disabled={!cart.length || loading}
                className="w-full bg-blue-600 text-white py-3.5 rounded-lg font-medium hover:bg-blue-700 
                         disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors flex items-center justify-center gap-2"
              >
                {loading ? (
                  <>
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  `Complete Checkout (NPR ${grandTotal.toFixed(2)})`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSelfCheckout;