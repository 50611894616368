import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import axios from "axios";
import * as XLSX from "xlsx";

// Color palette with 12 distinct colors
const COLORS = [
  "#0088FE", "#00C49F", "#FFBB28", "#FF8042",
  "#A569BD", "#DC7633", "#5DADE2", "#58D68D",
  "#EB984E", "#AF7AC5", "#F1948A", "#7DCEA0"
];

const WeeklySaleChart = () => {
  const [salesData, setSalesData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get("https://api.pranucollection.com/api/weekly-sales");
        if (response.data.success) {
          setSalesData(response.data.data);
          setStartDate(response.data.dateRange.startDate);
          setEndDate(response.data.dateRange.endDate);
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchSalesData();
  }, []);

  // Stable color assignment based on category name
  const getCategoryColor = (category) => {
    const hash = Array.from(category).reduce(
      (acc, char) => acc + char.charCodeAt(0),
      0
    );
    return COLORS[hash % COLORS.length];
  };

  // Prepare and sort data alphabetically
  const sortedChartData = salesData
    .map((category) => ({
      category: category.category,
      totalQuantity: category.products.reduce((sum, product) => sum + product.totalQuantity, 0),
    }))
    .sort((a, b) => a.category.localeCompare(b.category));

  const downloadExcel = () => {
    const excelData = [
      [`Sales Data from ${startDate} to ${endDate}`],
      ["", ""], // Empty row for spacing
      ["Category", "Total Quantity"]
    ];

    // Add sorted data
    sortedChartData.forEach(({ category, totalQuantity }) => {
      excelData.push([category, totalQuantity]);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Weekly Sales");
    XLSX.writeFile(workbook, `Weekly_Sales_${startDate}_to_${endDate}.xlsx`);
  };

  return (
    <div className="bg-white p- transition-shadow duration-300">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">Weekly Sales by Category</h2>
        <button
          onClick={downloadExcel}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Download Excel
        </button>
      </div>
      <p className="text-sm text-gray-600">
        Showing data from <b>{startDate}</b> to <b>{endDate}</b>
      </p>

      <div className="mt-8">
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              data={sortedChartData}
              dataKey="totalQuantity"
              nameKey="category"
              cx="50%"
              cy="50%"
              outerRadius={100}
              label
            >
              {sortedChartData.map((entry) => (
                <Cell 
                  key={entry.category} 
                  fill={getCategoryColor(entry.category)}
                />
              ))}
            </Pie>
            <Tooltip 
              formatter={(value) => [`${value} units`, "Quantity"]}
              contentStyle={{
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "4px",
                padding: "8px"
              }}
            />
            <Legend 
              wrapperStyle={{ paddingTop: "20px" }}
              formatter={(value) => (
                <span className="text-sm text-gray-600">{value}</span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default WeeklySaleChart;