import axios from "axios";

// export const baseURL = "http://localhost:5000"; 
export const baseURL = "https://api.pranucollection.com";
const Api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
const config = {
  headers: {
    authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};
console.log('Api: ', {...Api});
// Category APi
export const addCategoryApi = (categoryData) => Api.post('/api/category/add-category', categoryData);
export const getAllCategoryApi = () => Api.get('/api/category/getAllCategory');
export const updateCategoryApi = (categoryId, categoryData) => Api.put(`/api/category/categories/${categoryId}`, categoryData);
export const deleteCategoryApi = (categoryId) => Api.delete(`/api/category/deleteCategory/${categoryId}`);
export const deleteSubCategoryApi = (subCategoryId) => Api.delete(`/api/subcategory/deleteSubCategory/${subCategoryId}`);
// Subcategory API
export const getAllSubcategoriesApi = () => Api.get('/api/subcategory/subcategories');
export const getSubCategoryByIdApi = () => Api.get('/api/subcategory/subcategory/:id');
export const addSubCategoryApi = (subCategoryData) => Api.post('/api/subcategory/addsubcategory', subCategoryData);
export const getSubCategoriesByCategoryApi = (categoryId) => Api.get(`/api/subcategory/category/${categoryId}`);
export const updateSubCategoryApi = (subCategoryId, subCategoryData) => Api.put(`/api/subcategory/subcategory/${subCategoryId}`, subCategoryData);
//order api
export const createOrderApi = (orderData) => Api.post('/api/order/createOrder', orderData);
export const getAllOrdersByUserIdApi = (userId) => Api.get(`/api/order/orders/${userId}`);
export const deleteOrderApi = (orderId) => Api.delete(`/api/order/deleteOrder/${orderId}`);
export const getAllOrderApi = () => Api.get('/api/order/getAllOrders');
export const updateOrderApi = (orderId, orderData) => Api.put(`/api/order/updateOrder/${orderId}`, orderData);
//get product by id 
export const getProductByIdApi = (id) => Api.get(`/api/product/getProductById/${id}`);

//get product by category
export const getProductByCategoryApi =(CatrgoryId)=>Api.get(`/api/product/category/${CatrgoryId}`)
//update rating
export const updateRatingApi = (productId, ratingData) => Api.put(`/api/product/updateProductRatingByUser/${productId}`, ratingData, config);

//delete product by id
export const deleteProductByIdApi = (id) => Api.delete(`/api/product/deleteProductById/${id}`);
//edit product by id
export const editProductByIdApi = (id, productData) => Api.put(`/api/product/updateProductById/${id}`, productData,{
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
// Product API
export const getAllProductApi = () => Api.get('/api/product/getAllProducts');
export const addProductApi = (productData) => Api.post('/api/product/add-product', productData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

//edit product api

// user auth api
export const loginApi = (loginData) => Api.post('/api/login', loginData);
//google login
export const googleLoginApi = (loginData) => Api.post('/api/auth/google', loginData);
// export const googleLogin = (googleToken) => {
//   return Api.post('/api/auth/google-login', { token: googleToken });
// };


export const registerApi = (registerData) => Api.post('api/register', registerData);
export const logoutUserApi =()=>Api.post("api/logout");
export const getProfileApi = () => Api.get('/api/profile', config);
export const updateProfileApi = (profileData) => {
  return Api.put('/api/updateProfile', profileData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};
export const updatePasswordApi = (passwordData) => {
  return Api.put('/api/change-password', passwordData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

// Gallery API
export const createGalleryApi = (galleryData) =>
  Api.post('/api/gallery/addGallery', galleryData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const getAllGalleryApi = () => Api.get('/api/gallery/getAllGallery');

export const getProductBySku = (sku) => Api.get(`/api/product/getProductBySku/${sku}`);

export const processCheckout = (checkoutData) => Api.post('/api/selfcheckout/create', checkoutData);

export const getAllCheckouts = () => Api.get('/api/selfcheckout/getallcheckouts');

// create banner
export const createBannerApi = (bannerData) =>
  Api.post('/api/banner/add', bannerData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const getAllBannersApi = () => Api.get('/api/banner/banners');
export const deleteBannerApi = (bannerId) => Api.delete(`/api/banner/bannersDelete/${bannerId}`);

export const getProductsByRatingApi = (userId, productId) => 
  Api.get(`/api/product/getProductRatingByUserId/${userId}/${productId}`);

export const getTotalCountsApi = () => Api.get('/api/totalCounts');
export const getDailySalesApi = () => Api.get('/api/dailysales');

export const getCategorySalesApi = () => Api.get('/api/category-sales');

export const processCheckouts = (checkoutData) => Api.post('/api/create', checkoutData);
export const processCheckoutApi = (checkoutData) => Api.post('/api/create', checkoutData);
export const getDailySales = () => axios.get(`${baseURL}/api/daily-sales`);
export const checkCustomer = (phone) => axios.get(`${baseURL}/api/check-customer/${phone}`);

export const getCustomerHistory = (phone) => axios.get(`${baseURL}/api/customer-history/${phone}`);
export const getProductAnalytics = () => axios.get(`${baseURL}/api/product-analytics`);
export const getAllCheckoutsApi = () => Api.get('/api/getallcheckouts');
