import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getAllCheckouts } from "../../../Apis/Api";
import * as XLSX from "xlsx";

const GetCheckoutAdmin = () => {
  const [checkouts, setCheckouts] = useState([]);
  const [filteredCheckouts, setFilteredCheckouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    fetchCheckouts();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      filterCheckoutsByDate(selectedDate);
    } else {
      setFilteredCheckouts(checkouts);
    }
  }, [checkouts, selectedDate]);

  const fetchCheckouts = async () => {
    try {
      const { data } = await getAllCheckouts();
      if (data.success) {
        setCheckouts(data.data);
        setFilteredCheckouts(data.data);
      } else {
        toast.error("Failed to fetch checkout records.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching checkout records.");
    } finally {
      setLoading(false);
    }
  };

  const filterCheckoutsByDate = (date) => {
    const filtered = checkouts.filter((checkout) => {
      const checkoutDate = new Date(checkout.createdAt);
      const selectedDate = new Date(date);

      return (
        checkoutDate.getFullYear() === selectedDate.getFullYear() &&
        checkoutDate.getMonth() === selectedDate.getMonth() &&
        checkoutDate.getDate() === selectedDate.getDate()
      );
    });

    setFilteredCheckouts(filtered);
  };

  const exportToExcel = () => {
    // Map filtered checkouts to structured data
    const checkoutData = filteredCheckouts.map((checkout, index) => {
      const productDetails = checkout.products
        .map((product) => {
          const discount =
            product.price === checkout.totalAmount
              ? "No discount applied"
              : `Npr. ${product.price - checkout.totalAmount} (Discount Applied)`;
  
          return [
            `Name: ${product.productId?.name || product.name}`,
            `Size: ${product.size}`,
            `Color: ${product.color}`,
            `Quantity: ${product.quantity}`,
            `Selling Price per quantity: Npr. ${product.price}`,
            `Discounted Price: ${discount}`,
          ]
            .filter((line) => line)
            .join("\n"); // Each attribute on a new line
        })
        .join("\n\n"); // Separate multiple products by an empty line
  
      // Payment methods breakdown
      const paymentMethods = {
        "Cash": checkout.paymentMethod === "cash" ? checkout.totalAmount : 0,
        "Esewa": checkout.paymentMethod === "esewa" ? checkout.totalAmount : 0,
        "Fone Pay": checkout.paymentMethod === "fonepay" ? checkout.totalAmount : 0,
        "Khalti": checkout.paymentMethod === "khalti" ? checkout.totalAmount : 0,
      };
  
      // Calculate profit
      const profitMade = checkout.products.reduce((total, product) => {
        const productPrice = product.productId?.price || 0;
        return total + (checkout.totalAmount - productPrice) * product.quantity;
      }, 0);
  
      return {
        "S.N": index + 1,
        "Date & Time": `${new Date(checkout.createdAt).toLocaleDateString()} ${new Date(checkout.createdAt).toLocaleTimeString()}`,
        "Product Details": productDetails,
        "Payment Method": checkout.paymentMethod.charAt(0).toUpperCase() + checkout.paymentMethod.slice(1),
        "Quantity": checkout.products.reduce((total, product) => total + product.quantity, 0),
        "Customer Name": checkout.customerFullName || "N/A",
        "Customer Mobile Number": checkout.mobileNumber || "N/A",
        "Remarks": checkout.remarks || "N/A",
        "Cash": paymentMethods["Cash"],
        "Esewa": paymentMethods["Esewa"],
        "Fone Pay": paymentMethods["Fone Pay"],
        "Khalti": paymentMethods["Khalti"],
        "Total Amount": checkout.totalAmount || "N/A",
        "Profit Made": profitMade,
      };
    });
  
    // Generate worksheet
    const ws = XLSX.utils.json_to_sheet(checkoutData);
  
    // Add footer row with formulas
    const totalRows = checkoutData.length + 1; // Header + data rows
    const footerRow = {
      "S.N": "",
      "Date & Time": "",
      "Product Details": "",
      "Payment Method": "",
      "Quantity": "",
      "Customer Name": "",
      "Customer Mobile Number": "",
      "Remarks": "",
      "Cash": { t: "s", f: `"Grand Total in Cash: NPR " & TEXT(SUM(I2:I${totalRows}), "#,##0.00")` },
      "Esewa": { t: "s", f: `"Grand Total in Esewa: NPR " & TEXT(SUM(J2:J${totalRows}), "#,##0.00")` },
      "Fone Pay": { t: "s", f: `"Grand Total in Fone Pay: NPR " & TEXT(SUM(K2:K${totalRows}), "#,##0.00")` },
      "Khalti": { t: "s", f: `"Grand Total in Khalti: NPR " & TEXT(SUM(L2:L${totalRows}), "#,##0.00")` },
      "Total Amount": { t: "s", f: `"Grand Total: NPR " & TEXT(SUM(M2:M${totalRows}), "#,##0.00")` },
      "Profit Made": { t: "s", f: `"Total Profit: NPR " & TEXT(SUM(N2:N${totalRows}), "#,##0.00")` },
    };
  
    XLSX.utils.sheet_add_json(ws, [footerRow], { skipHeader: true, origin: -1 });
  
    // Set column widths
    const colWidths = Object.keys(checkoutData[0]).map((key) => {
      switch (key) {
        case "Product Details": return { wch: 50 };
        case "Cash":
        case "Esewa":
        case "Fone Pay":
        case "Khalti": return { wch: 22 };
        case "Total Amount":
        case "Profit Made": return { wch: 20 };
        default: return { wch: 18 };
      }
    });
    ws['!cols'] = colWidths;
  
    // Style definitions
    const borderStyle = { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };
    const headerStyle = { font: { bold: true }, alignment: { vertical: "center", horizontal: "center" } };
    const dataStyle = { alignment: { wrapText: true, vertical: "top", horizontal: "left" } };
  
    // Apply styles to all cells
    Object.keys(ws).forEach(cellKey => {
      const cell = ws[cellKey];
      if (cell && cell.v !== undefined) {
        cell.s = { ...(cell.s || {}), border: borderStyle, ...dataStyle };
      }
    });
  
    // Style header row
    Object.keys(checkoutData[0]).forEach((_, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
      if (ws[cellAddress]) {
        ws[cellAddress].s = { ...ws[cellAddress].s, ...headerStyle };
      }
    });
  
    // Style footer row
    const footerRowIndex = checkoutData.length + 1; // 0-based index
    Object.keys(footerRow).forEach((_, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ r: footerRowIndex, c: colIndex });
      if (ws[cellAddress]) {
        ws[cellAddress].s = { 
          ...(ws[cellAddress].s || {}), 
          font: { bold: true },
          alignment: { vertical: "top", horizontal: "left" }
        };
      }
    });
  
    // Format numeric columns
    const currencyColumns = [8, 9, 10, 11, 12, 13]; // I to N (0-based)
    for (let row = 1; row <= checkoutData.length; row++) {
      currencyColumns.forEach(col => {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        if (ws[cellAddress] && ws[cellAddress].t === 'n') {
          ws[cellAddress].z = '"NPR "#,##0.00';
        }
      });
    }
  
    // Create workbook and export
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Checkouts");
    XLSX.writeFile(wb, "Checkouts.xlsx");
  };
  



  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-6">
        Self-Checkout Records
      </h2>

      <div className="mb-4">
        <label htmlFor="dateFilter" className="mr-2">
          Filter by Date:
        </label>
        <input
          type="date"
          id="dateFilter"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
      </div>

      {loading ? (
        <div className="text-center text-blue-500">Loading...</div>
      ) : filteredCheckouts.length === 0 ? (
        <div className="text-center text-gray-500">
          No checkout records found.
        </div>
      ) : (
        <>
          <button
            onClick={exportToExcel}
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Export to Excel
          </button>

          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 px-4 py-2">#</th>
                <th className="border border-gray-300 px-4 py-2">
                  Date & Time
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Product Details
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Payment Method
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Total Amount
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Profit Made
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredCheckouts.map((checkout, index) => (
                <tr key={checkout._id} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {index + 1}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {new Date(checkout.createdAt).toLocaleDateString()} <br />
                    {new Date(checkout.createdAt).toLocaleTimeString()}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {checkout.products.map((product, i) => (
                      <div key={i} className="mb-4">
                        <p>
                          <strong>Name:</strong>{" "}
                          {product.productId?.name || product.name}
                        </p>
                        <p>
                          <strong>Size:</strong> {product.size}
                        </p>
                        <p>
                          <strong>Color:</strong> {product.color}
                        </p>
                        <p>
                          <strong>Quantity:</strong> {product.quantity}
                        </p>
                        <p>
                          <strong>Selling Price per quantity:</strong> Npr.{" "}
                          {product.price}
                        </p>
                        <p>
                          <strong>Discounted Price:</strong>{" "}
                          {product.price === checkout.totalAmount
                            ? "No discount applied"
                            : `Npr. ${checkout.totalAmount - product.price
                            } (Discount Applied)`}
                        </p>
                        {checkout.customerFullName && (
                          <p>
                            <strong>Customer Name:</strong>{" "}
                            {checkout.customerFullName}
                          </p>
                        )}
                        {checkout.mobileNumber && (
                          <p>
                            <strong>Customer Mobile Number:</strong>{" "}
                            {checkout.mobileNumber}
                          </p>
                        )}
                        {checkout.remarks && (
                          <p>
                            <strong>Remarks:</strong> {checkout.remarks}
                          </p>
                        )}
                      </div>
                    ))}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {checkout.paymentMethod.charAt(0).toUpperCase() +
                      checkout.paymentMethod.slice(1)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    Npr. {checkout.totalAmount}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    <span
                      className={
                        checkout.products.reduce((total, product) => {
                          const productPrice = product.productId?.price || 0;
                          const quantity = product.quantity || 0;

                          // Total for this product (price * quantity)
                          const totalProductValue = productPrice * quantity;

                          // Calculate profit per product (product value - checkout totalAmount)
                          const profitPerProduct = checkout.totalAmount - totalProductValue;

                          // Add to the total profit
                          return total + profitPerProduct;
                        }, 0) > 0
                          ? "text-green-500" // Profit is positive, show in green
                          : "text-red-500"   // No profit or negative profit, show in red
                      }
                    >
                      Npr.{" "}
                      {checkout.products.reduce((total, product) => {
                        const productPrice = product.productId?.price || 0;
                        const quantity = product.quantity || 0;

                        // Total for this product (price * quantity)
                        const totalProductValue = productPrice * quantity;

                        // Calculate profit per product (product value - checkout totalAmount)
                        const profitPerProduct = checkout.totalAmount - totalProductValue;

                        // Add to the total profit
                        return total + profitPerProduct;
                      }, 0)}
                    </span>
                  </td>



                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default GetCheckoutAdmin;
