import React, { useState } from 'react';
import SelfCheckout from './SlefCheckout';
import GetCheckoutAdmin from './GetCheckoutAdmin';
import NewSelfCheckout from './NewSelfCheckout';
import NewGetCheckoutAdmin from './NewGetCheckoutAdmin';

const CheckoutAdmin = () => {
  const [view, setView] = useState('adds');

  return (
    <div className="p-4">
      <div className="pt-10 mt-3">
        <div className="flex justify-start gap-4">
          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'adds' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('adds')}
          >
            Checkout Product in Store
          </button>

          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'views' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('views')}
          >
            View Sold Products in Store
          </button>

          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'add' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('add')}
          >
            Old Checkout
          </button>
          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'view' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('view')}
          >
            Old Sold Products in Strore
          </button>

        </div>
        {view === 'adds' && <NewSelfCheckout />}
        {view === 'views' && <NewGetCheckoutAdmin />}
        {view === 'add' && <SelfCheckout />}
        {view === 'view' && <GetCheckoutAdmin />}
      </div>
    </div>
  );
};

export default CheckoutAdmin;
